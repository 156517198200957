import React from 'react';
import ClickerGame from './ClickerGame';

function Games() {
  return (
    <div>
      <ClickerGame />
    </div>
  );
}

export default Games;